import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Main.css';

const Main = () => {
  const [showPolicy, setShowPolicy] = useState(false);
  const [showPolicy2, setShowPolicy2] = useState(false);

  useEffect(() => {
    const observer1 = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowPolicy(true);
        } else {
          setShowPolicy(false);
        }
      },
      { threshold: 0.5 }
    );

    const observer2 = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowPolicy2(true);
        } else {
          setShowPolicy2(false);
        }
      },
      { threshold: 0.5 }
    );

    const element1 = document.getElementById("policy1");
    const element2 = document.getElementById("policy2");

    if (element1) {
      observer1.observe(element1);
    }

    if (element2) {
      observer2.observe(element2);
    }

    return () => {
      if (element1) {
        observer1.unobserve(element1);
      }
      if (element2) {
        observer2.unobserve(element2);
      }
    };
  }, []);

  const policyText = `
    " มุ่งมั่นในการส่งมอบสินค้า และบริการที่มีคุณภาพ<br /> 
    มุ่งเน้นการพัฒนาระบบบริหารงานกระบวนการ<br /> 
    และการควบคุมคลังสินค้าให้มีประสิทธิภาพอย่างต่อเนื่อง<br /> 
    เพื่อคลอบคลุมความต้องการ ความพึงพอใจของลูกค้า<br /> 
    และเพื่อความก้าวหน้าในการดำเนินธุรกิจอย่างยั่งยืน "
  `;

  return (
    <div style={{ paddingTop: '90px' }}>
      <Navbar
        variant="dark"
        className="d-flex justify-content-center p-4"
        style={{
          position: 'relative',
          marginTop: '0px',
          width: '100%',
          backgroundImage: 'url(/nav2.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white',
          textAlign: 'center',
          fontSize: '25px',
          height: '500px',
        }}
      >
        <div style={{ padding: '30px' }}>
          <h5 style={{ fontSize: '50px', fontWeight: 'bold', marginBottom: '20px' }}>
            ยินดีต้อนรับสู่
          </h5>
          <h3 style={{ fontSize: '40px', fontWeight: 'bold', marginBottom: '20px' }}>
            บริษัท ดีพี แมททีเรียล พาร์ท จำกัด
          </h3>
          <p style={{ fontSize: '20px', lineHeight: '1.5' }}>
            ศูนย์รวมน็อตและสกรูครบวงจร ที่คุณสามารถเลือกซื้อหรือสั่งผลิตตามแบบเฉพาะได้
            เพื่อให้เหมาะสมกับการใช้งาน พร้อมการผลิตที่ได้มาตรฐานโดยทีมงานมืออาชีพทุกขั้นตอน
          </p>
        </div>
      </Navbar>

      <div style={{ overflow: 'hidden' }}> {/* ครอบกล่องทั้งหมด */}
  {/* กล่องนโยบาย 1 */}
  <div
    id="policy1"
    style={{
      backgroundImage: 'url(/nav4.jpg)', // เส้นทางไฟล์ภาพ
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '60px',
      opacity: showPolicy ? 1 : 0,
      transform: showPolicy ? 'translateX(0)' : 'translateX(100%)',
      transition: 'all 1s ease-in-out',
      border: 'none',
      width: '120vw',  // เพิ่มความกว้างเกินขนาดหน้าจอ
      borderRadius: '0px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '400px',
      margin: '20px 0',
      marginTop: '0px',
      marginBottom: '0px',
      position: 'relative',
      right: showPolicy ? '0' : '100%',
      textAlign: 'right',
      wordWrap: 'break-word',
    }}
  >
    <div style={{ maxWidth: '80%', textAlign: 'left', paddingLeft: '350px', paddingRight: '290px' }}>
      <h2
        style={{
          fontSize: '35px',
          fontWeight: 'bold',
          lineHeight: '1.5',
          textAlign: 'left',
          wordWrap: 'break-word',
          
        }}
      >
        นโยบายบริษัท
      </h2>
      <p
        style={{
          fontSize: '18px',
          lineHeight: '1.8',
          wordWrap: 'break-word',
          maxWidth: '100%',
          marginBottom: '0px',
          marginRight: '0px',
          textAlign: 'left',
          display: 'inline-block',
        }}
        dangerouslySetInnerHTML={{ __html: policyText }}
      />
    </div>
  </div>
</div>



      <div style={{ overflow: 'hidden' }}> {/* ครอบกล่องทั้งหมด */}
      {/* กล่องนโยบาย 2 */}
      <div
        id="policy2"
        style={{
          backgroundImage: 'url(/nav5.webp)', // เส้นทางไฟล์ภาพ
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '60px',
          marginTop: '0px', // ตั้งค่า marginTop ให้เป็น -20px เพื่อให้ติดกับกล่องแรก
          opacity: showPolicy2 ? 1 : 0,
          transform: showPolicy2 ? 'translateX(0)' : 'translateX(-100%)', // เลื่อนจากขวาไปซ้าย
          transition: 'all 1s ease-in-out',
          border: 'none',
          width: '120vw',  // เพิ่มความกว้างเกินขนาดหน้าจอ
          borderRadius: '0px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'flex-start', // ให้ข้อความอยู่ทางซ้าย
          alignItems: 'center',
          height: '400px',
          position: 'relative',
          left: showPolicy2 ? '0' : '100%', // ใช้ left แทน right เพื่อให้มันเลื่อนจากขวาไปซ้าย
          textAlign: 'left', // ข้อความชิดซ้าย
          wordWrap: 'break-word',
        }}
      >
        <div style={{ maxWidth: '80%', textAlign: 'left', paddingRight: '20px' }}>
          <h2
            style={{
              fontSize: '35px',
              fontWeight: 'bold',
              marginBottom: '20px',
              lineHeight: '1.5',
              textAlign: 'left', // ข้อความชิดซ้าย
              wordWrap: 'break-word',
            }}
          >
            เกี่ยวกับเรา
          </h2>
          <p
            style={{
              fontSize: '18px',
              lineHeight: '1.8',
              wordWrap: 'break-word',
              maxWidth: '100%',
              marginBottom: '0px',
              textAlign: 'left', // ข้อความชิดซ้าย
              display: 'inline-block',
            }}
          >
            บริษัท ดีพี แมททีเรียล พาร์ท (DPM) เป็นผู้ผลิตน็อตและสกรูที่มีประสบการณ์ยาวนานในอุตสาหกรรม<br />
            เรามุ่งมั่นที่จะให้บริการที่มีคุณภาพสูงและตอบสนองความต้องการของลูกค้าในทุกภาคส่วน<br />
            เราเน้นการผลิตและจัดจำหน่ายสินค้าที่มีมาตรฐานสูงเพื่อเสริมสร้างความแข็งแกร่งให้กับธุรกิจของลูกค้า<br />
            ด้วยบริการที่มีคุณภาพและการจัดส่งที่ตรงเวลาเพื่อความพึงพอใจสูงสุดของลูกค้า<br />
            บริษัทของเรายังคงพัฒนาผลิตภัณฑ์และบริการอย่างต่อเนื่องเพื่อตอบสนองทุกความต้องการของลูกค้า
          </p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
