import React, { useState, useEffect } from 'react';  // เพิ่ม useState และ useEffect
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Contact from './components/contact';
import Main from './components/Main'; // ตรวจสอบให้แน่ใจว่าเส้นทางถูกต้อง
import { Navbar, Nav } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa'; // Import React Icons
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';  // ใช้ axios ในการดึงข้อมูล

const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};


const MainContent = () => {
  const navigate = useNavigate();  // ใช้ useNavigate สำหรับการจัดการการนำทาง
  const [expanded, setExpanded] = useState(false);  // ใช้ useState สำหรับการจัดการสถานะของ Navbar

  const handleAnchorNavigation = (anchor) => {
    navigate('/main');  // เปลี่ยนเส้นทางไปยังหน้า /main
    document.getElementById(anchor)?.scrollIntoView({ behavior: 'smooth' });  // เลื่อนหน้าจอไปยัง anchor ที่ระบุ
  };

  const handleNavClick = () => {
    setExpanded(false);  // ปิดเมนู Navbar เมื่อคลิก
  };

  // useEffect สำหรับดึงข้อมูลจาก Backend เมื่อ Component โหลดเสร็จ
  useEffect(() => {
    // ดึงข้อมูลจาก API
    axios.get('https://dp.co.th/api/products')
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);  // [] หมายถึงไม่ให้ useEffect ทำงานซ้ำเมื่อมีการเปลี่ยนแปลง state หรือ props

  
  return (
    <div style={{ minHeight: '100vh' }}>
    {/* Navbar ด้านบน */}
    <Navbar
      variant="dark"
      expand="lg"
      className="p-1"
      style={{
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: '#ff6600',
        height: '90px',
      }}
      expanded={expanded}  // ใช้สถานะ expanded
    >
      {/* โลโก้และชื่อบริษัท */}
      <div className="d-flex align-items-center">
        <img 
          src="/logop1.png" 
          alt="Logo" 
          style={{ height: '80px', marginRight: '10px' }}  
        />
        <div>
          <Navbar.Brand as={Link} to="/" className="fs-6 text-white">
            ดีพี แมททีเรียล พาร์ท จำกัด
          </Navbar.Brand>
          <p style={{ textAlign: 'left', fontSize: '10px', color: '#ffffff' }}>
            DP MATERIAL PART .CO.,LTD
          </p>
        </div>
      </div>
  
      {/* ปุ่มเปิดเมนู (Hamberger Menu) */}
      <Navbar.Toggle 
        aria-controls="navbar-nav" 
        className="border-0" 
        onClick={() => setExpanded(!expanded)}  // เปลี่ยนสถานะเมื่อคลิก
      />
  
      {/* เมนูหลัก */}
      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
        <Nav className="ms-auto d-flex flex-column flex-lg-row align-items-lg-center text-center text-lg-start">
          <Nav.Link as={Link} to="/main" className="fs-5 text-white p-2" onClick={handleNavClick}>หน้าหลัก</Nav.Link>
          <Nav.Link onClick={() => { handleAnchorNavigation('policy2'); handleNavClick(); }} className="fs-5 text-white p-2">เกี่ยวกับเรา</Nav.Link>
          <Nav.Link as={Link} to="/dashboard" className="fs-5 text-white p-2" onClick={handleNavClick}>สินค้า</Nav.Link>
          <Nav.Link as={Link} to="/contact" className="fs-5 text-white p-2" onClick={handleNavClick}>ติดต่อเรา</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <style>
  {`
    @media (max-width: 991px) {
      .navbar-collapse {
        background-color: #ff6600; /* ให้เมนูยุบลงมามีพื้นหลังสีเดียวกับ Navbar */
        padding: 20px; /* เพิ่ม padding เพื่อให้เมนูดูสะดวกขึ้น */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* เพิ่มเงาให้เมนู */
      }
      .navbar-nav {
        flex-direction: column !important;
        text-align: center;
        margin-left: 0; /* ให้การจัดตำแหน่งเมนูที่ถูกต้อง */
        width: 100%; /* ให้เมนูขยายเต็มพื้นที่ */
      }
      .navbar-nav .nav-link {
        border-bottom: 1px solid #ddd; /* เส้นแบ่งระหว่างเมนู */
        padding: 15px 0; /* เพิ่มระยะห่างระหว่างเมนู */
      }
      .navbar-nav .nav-link:hover {
        background-color: #FF8C00; /* เพิ่มสีเมื่อเมาส์ชี้ */
      }

      /* ปรับตำแหน่งของ Navbar.Toggle ให้ถูกต้อง */
      .navbar-toggler {
        position: absolute;
        top: 20px; /* ปรับตำแหน่งจากด้านบน */
        right: 20px; /* ปรับตำแหน่งจากด้านขวา */
      }
    }
  `}
</style>


      {/* Routes ที่จะทำให้หน้า Dashboard และ Contact แสดง */}
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/main" element={<Main />} />
        <Route path="/about" element={<Main />} />
      </Routes>

      {/* Navbar ด้านล่าง */}
      <div style={{ marginTop: '50px' }}>
        <Navbar
          variant="light"
          expand="lg"
          className="d-flex justify-content-between p-3"
          style={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            zIndex: 999, 
            backgroundColor: '#FF6600',
            borderTop: '2px solid #ff6600',
          }}
        >
          <div className="d-flex align-items-center">
            <img 
              src="/logop1.png" 
              alt="Logo" 
              style={{ height: '150px', marginRight: '0px' }} 
            />
            <div>
              <Navbar.Brand as={Link} to="/" className="fs-8 text-white">
                ดีพี แมททีเรียล พาร์ท จำกัด
              </Navbar.Brand>
              <p style={{
                textAlign: 'left',
                fontSize: '12px',
                color: '#ffff',
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                margin: 0, 
                lineHeight: '1.2'
              }}>
                77/6 แฟคตอรี่แลนด์ 1 หมู่ 12 ต.ไร่ขิง อ.สามพราน จ.นครปฐม 73210
              </p>
              <Nav.Link href="mailto:sirawat.dpm@gmail.com" className="fs-8 text-white" style={{ margin: 0, padding: 0 }}>
                <span style={{
                  marginRight: '8px',
                  fontSize: '12px',
                  lineHeight: '1.2',
                }}>
                 Email: sirawat.dpm@gmail.com
                </span>
              </Nav.Link>
            </div>
          </div>
          <Nav className="d-flex flex-column align-items-end w-100">
            <div className="fs-4 text-white mb-0" style={{ marginBottom: '10px' }}>ช่องทางติดต่อ</div>
            <div className="fs-4 text-white mb-0" style={{ marginBottom: '10px' }}>092-698-8566</div>
            <div className="fs-4 text-white mb-0" style={{ marginBottom: '10px' }}>083-463-6952</div>
            <div className="d-flex justify-content-end align-items-center">
              <Nav.Link href="https://line.me/ti/p/~0926988566" className="fs-4 text-dark">
                <img 
                  src="/line1.png" 
                  alt="Line"
                  style={{ marginRight: '0px', width: '35px', height: '35px' }} 
                />
              </Nav.Link>
              <Nav.Link href="https://mail.google.com/mail/?view=cm&fs=1&to=sirawat.dpm@gmail.com" target="_blank" className="fs-4 text-white">
                <FaEnvelope style={{ marginRight: '2px', fontSize: '2.5rem' }} />
              </Nav.Link>
            </div>
          </Nav>
        </Navbar>
      </div>
    </div>
  );
};

export default App;
