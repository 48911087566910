import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // นำเข้าไอคอนจาก react-icons
import styled from 'styled-components';

const ContactInfo = styled.div`
  p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  /* @media สำหรับมือถือ */
  @media (max-width: 768px) {
    p {
      font-size: 1rem;
    }

    h2 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.5rem;
    }
  }

  /* @media สำหรับหน้าจอเล็กๆ เช่นมือถือ */
  @media (max-width: 480px) {
    p {
      font-size: 0.9rem;
    }

    h2 {
      font-size: 1.8rem;
    }

    h4 {
      font-size: 1.2rem;
    }
  }
`;

const Contact = () => {
  return (
    <Container className="my-5">
      <Row className="d-flex align-items-start">
        {/* แผนที่อยู่ด้านซ้าย */}
        <Col md={6} className="p-0" style={{ marginTop: '100px' }}>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.634539950846!2d100.29906817467992!3d13.740562286650144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29549be854a79%3A0x6d184bafcab7ac45!2z4LmB4Lif4LiE4LiV4Lit4Lij4Li14LmI4LmB4Lil4LiZ4LiU4LmMMQ!5e0!3m2!1sth!2sth!4v1740730619794!5m2!1sth!2sth"
              width="100%"
              height="100%"
              style={{
                border: 0,
                borderRadius: '0px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                aspectRatio: '1',
              }}
              allowFullScreen
              loading="lazy"
              title="แผนที่ของบริษัท"
            ></iframe>
          </div>
        </Col>

        {/* ข้อมูลติดต่ออยู่ด้านขวา */}
        <Col md={6} className="d-flex flex-column justify-content-between p-4" style={{ marginTop: '70px' }}>
          <div>
            <h2 style={{ fontSize: '2.5rem' }}>ติดต่อเรา</h2>
            <h4 style={{ fontSize: '2rem', color: '#ff6600' }}>ดีพี แมททีเรียล พาร์ท จำกัด</h4>
          </div>
          <ContactInfo>
            <p>
              <FaMapMarkerAlt className="me-2" style={{ color: '#ff6600' }} />
              77/6 แฟคตอรี่แลนด์ 1 หมู่ 12 ตำบลไร่ขิง อำเภอสามพราน จังหวัดนครปฐม 73210
            </p>
            <p>
              <FaPhoneAlt className="me-2" style={{ color: '#ff6600' }} />
              092-698-8566 , 083-463-6952
            </p>
            <p>
              <FaEnvelope className="me-2" style={{ color: '#ff6600' }} />
              <a href="mailto:sirawat.dpm@gmail.com">
                sirawat.dpm@gmail.com
              </a>
            </p>
            <p>
              <img src="/line1.png" alt="Line" />
              <a href="https://line.me/ti/p/~0926988566" target="_blank" rel="noopener noreferrer">
                @092-698-8566
              </a>
            </p>
            <p>จันทร์ - เสาร์ เวลา 08:00-17:00</p>
          </ContactInfo>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
